.layout-chunk{
  .big-title-name{
    text-align: center;
    font-size: 1.5em;
  }
  .ant-pro-layout-content{
    padding: inherit;
  }
  .ant-pro-global-header-logo-mix{
    .user-chunk{
      position: relative;
      .exit-button{
        display: none;
      }
    }
    &:hover{
      .user-chunk{
        position: relative;
        .exit-button{
          display: block;
          position: absolute;
          background: #fff;
          width: 15.5vw;
          font-size: 16px;
        }
      }
    }
  }
}